.parent-directory-button-div-image {
    max-height: 50px;
}
.parent-directory-button-div {
    padding: 1rem;
    border: 1px solid black;
    border-radius: 16px;
    transition: 0.3s;
    background-color: #e6e6e6;
    min-width: 250px;
    cursor: pointer;
    position: relative;
}

.options-files-home {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    padding: 0.5rem;
    background-color: transparent;
}

.path-home-show {
    text-align: center;
}

.parent-directory-button-div:hover {
    background-color: #b5b5b5;
}

.path-div {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
}

.link {
    text-decoration: none;
    color: black;
}

.logo-upload-button {
    width: 50px;
}

.button-uplaod-files {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 16px;
}

.button-upload-no-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 16px;
}

.btn-upload {
    border: 1px solid black;
    border-radius: 5px;
    transition: 0.3s;
}
.btn-upload:hover {
    background-color: #b5b5b5;
    border: 1px solid black;
}

.content-clickable-directory{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.container-home {
    background: white;
    border-radius: 16px;
    padding: 1rem;
}

.grid-item-home {
    min-width: 260px;
}


.alert-success {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
}

.not-directory {
    text-align: center;

}

.not-directory img {
    max-width: 100%;
    max-height: 100%;
}