.preview-modal-content {
    position: relative;
}

.preview-modal-content h2 {
    width: 90%;
}

.isImageModalPreview {
    width: 50%;
}

.buttonClosePreview {
    position: absolute;
    top: 1%;
    right: 5%;
    width: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.buttonClosePreview img {
    width: 30px;
    height: 20px;
}

.preview-image-type-file {
    width: 40%;
    margin: 0 auto;
    display: flex;
}


.preview-image-type-file img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/*media query menos de 600px*/
@media screen and (max-width: 600px) {
    .preview-modal-content-preview {
        inset: 0px !important;
    }
    .preview-modal-content h2 {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .preview-image-type-file {
        width: 100%;
    }
}