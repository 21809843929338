.form-input-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.div-create-folder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background-color: white;
    border-radius: 16px;
    width: 80%;
    max-width: 600px;
}

.divCheckedPermissionsFolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}