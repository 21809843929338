.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 11%;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.listaBotonesHeader {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 10px;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.titulo span {
    font-size: 1.7em;
    font-weight: bold;
    color: black;
}

.logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
}

.logo-with-border {
    border: 1px solid dimgray;
}

.div-marco-header {
    width: 80px;
    height: 80px;
    position: relative;
}

.profile-name-div-header {
    width: 80%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo-header {
    width: 80px;
    height: 80px;
}

.search-usernames-header {
    width: 300px;
}

.buscador {
    text-align: -webkit-center;
    /*width: 100%;*/
}

.img-logout-header {
    width: 50px;
    height: 50px;
}

.level-number-header {
    position: absolute;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 0.6rem;
}

.estilo-marco-1-header {
    bottom: -19.5%;
    left: 50%;
}
.estilo-marco-2-header {
    bottom: -18%;
    left: 50%;
}

.estilo-marco-3-header {
    bottom: -11.5%;
    left: 50%;
}

.estilo-marco-4-header {
    bottom: -11%;
    left: 50%;
}

.estilo-marco-5-header {
    bottom: -13%;
    left: 50%;
}

@media screen and (max-width: 450px) {
    .titulo span {
        font-size: 1em;
    }
}
@media screen and (max-width: 600px) {
    .header {
        height: 10%;
    }
}