body {
    margin: 0;
    padding: 0;
    background-image: linear-gradient(to right top, #c8f4f4, #c1e3eb, #bcd2dd, #b7c2cd, #b0b3ba, #a5aaae, #9da1a2, #969797, #8e8f8f, #868787, #7f8080, #777878);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.main {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}