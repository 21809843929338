.main-profile {
    display: flex;
    gap: 1rem;
    width: 100%;
}
.nav-bar-routes-profile {
    flex-direction: row;
    flex-wrap: wrap;
}

.first-part-profile-div {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 16px;
    gap: 1rem;
    padding: 1rem;
    margin-left: 1rem;
}

.second-part-profile-div {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 16px;
    gap: 1rem;
    padding: 1rem;
    margin-right: 1rem;
}

.profile-name-div {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.level-number {
    position: absolute;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5rem;
}

.estilo-marco-1 {
    bottom: -2%;
    left: 50%;
}
.estilo-marco-2 {
    bottom: 0;
    left: 50%;
}

.estilo-marco-3 {
    bottom: 5%;
    left: 50%;
}

.estilo-marco-4 {
    bottom: 6%;
    left: 50%;
}

.estilo-marco-5 {
    bottom: 4%;
    left: 50%;
}

.profile-picture {
    border-radius: 50%;
    max-width: 100%;
}

.div-child-marco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.foto-marco {
    width: 100%;
    z-index: 122220;
}

.div-marco {
    max-width: 300px;
    min-width: 230px;
    height: 262px;
    position: relative;
}

.div-missions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profile-username {
    color: dimgray;
}

.profile-edit-div {
    width: 100%;
}

.profile-edit-button {
    border: 0.5px solid dimgray;
    width: 100%;
}

.profile-edit-button:hover {
    background: #d0cbcb;
    border: 1px solid dimgray;
    color: black;
}

.profile-follows-div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-items: flex-start;
}

.profile-follows-div h3 {
    font-size: 0.9rem;
    color: dimgray;
    margin: 0;
}

.profile-follows-image {
    max-width: 30px;
}

.profile-follows-image-point {
    max-width: 30px;
}

.loading-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.profile-menu-div {
    justify-content: flex-start;
    width: 100%;
}

.icon-nav-bar-profile {
    max-width: 30px;
}

.li-nav-bar-profile {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    padding: 0.5rem;
    cursor: pointer;
}

.profile-content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
}

.profile-content-div p {
    margin: 0;
    color: dimgray;
    font-size: 0.9rem;
}

.has-readme-profile {
    border: 0.8px solid dimgray;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
}

@media screen and (max-width: 800px) {
    .main-profile {
        flex-direction: column;
    }
    .first-part-profile-div {
        width: 100%;
        margin: 0;
    }
    .second-part-profile-div {
        width: 100%;
        margin: 0;
    }
    .profile-picture-div {
        max-width: 300px;
    }
}