.div-form-delete {
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 1rem;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    width: 80%;
    max-width: 600px;
}