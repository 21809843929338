.imagen-shared-by {
    position: absolute;
    bottom: 2%;
    left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.7rem;
}

.imagen-shared-by-top {
    position: absolute;
    top: 2%;
    left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.7rem;
}

.imagen-shared-by-top img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.span-username {
    font-weight: bold;
    font-size: 0.6rem;
}

.imagen-shared-by img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.div-component-file {
    position: relative;
}

.chincheta-absolute {
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
}

.link-shared-by {
    color: gray;
    text-decoration: none;
}