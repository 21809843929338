.form-input-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.div-upload-files {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background-color: white;
    padding: 1rem;
    width: 80%;
    max-width: 600px;
}

.file-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    border: 2px dashed gray;
    border-radius: 5px;
    font-size: 20px;
    color: gray;
    cursor: pointer;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  .file-input:hover {
    border-color: black;
    color: black;
    cursor: pointer;
  }
  
  .file-input label {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  #file-names-list {
    margin-top: 10px;
    text-align: center;
    list-style: none;
  }
  
  #file-preview {
    margin-top: 10px;
    max-width: 100%;
    max-height: 100%;
  }
.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20rem; }
.toggle.ios .toggle-handle { border-radius: 20rem; }