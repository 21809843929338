.container {
    text-align: center;
    
}

.close-modal {
    text-align: left;
}

.error {
    color: red;
    font-size: 1.3rem;
}

/* .tituloLogin {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin-bottom: 50px;
    margin-top: -80px;
    position: flex;
}

.loginButton {
    margin-top: 30px;
    background-image: url(loginButton.png);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    border: none;
    width: 21%;
    height: 60px;
    border-radius: 5px;
    background-color: white;
    animation: none;
}

.username {
    border: 1px solid #000;
    background-image: url(userLoginIcon.png);
    background-size: 33px 33px;
    background-repeat: no-repeat;
    background-position: 3px 2px;
    padding-left: 20px;
    width: 80%;
    text-align: center;
    display: flex;
    margin: auto;
}

.password {
    background-image: url(userPasswordIcon.png);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: 3px 2.5px;
    border: 1px solid #000;
    padding-left: 30px;
    width: 80%;
    text-align: center;
    display: flex;
    margin: auto;
}

.userPass {
    margin-top: 20px;
} */
.iconLogin {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.divInputLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.divInputLoginVerification {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
}

.divInputLoginVerification p {
    font-size: 0.8em;
    margin: 0;
}

.formLogin {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
}

.inputLogin {
    width:90%;
}
.tituloLogin {
    padding-bottom: 5%;
}
.loginButton {
    transition: 1s all;
    width: 30%;
    margin: 0 auto;
}
.loginButton:hover {
    cursor: pointer;
    scale: 1.1;
}
.miraSpam {
    color: red;
}